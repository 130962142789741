<template>
	<div class="v3_46">
		<div class="v21_5"></div>
		<div class="v21_77">
			<div class="v21_78">
				<img src="@/assets/images/logo-white.svg">
			</div>
		</div>
		<span class="v21_24">Subscribe the waiting list 🚀</span>

		<!-- Begin Mailchimp Signup Form -->
		<div id="mc_embed_signup" class="v21_25">
			<form
				action="https://studio.us6.list-manage.com/subscribe/post?u=db37477a78610a7fd68c1eb65&amp;id=39e8d41aaf"
				method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate"
				target="_blank" novalidate>
				<div id="mc_embed_signup_scroll" class="v21_26">
					<div class="v21_27">
						<input type="email" value="" name="EMAIL" class="email" id="mce-EMAIL"
						       placeholder="your@email.com" required>
					</div>
					<button value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="v21_29" type="submit">
						<i class='bx bxs-send'></i>
					</button>

					<!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
					<div style="position: absolute; left: -5000px;" aria-hidden="true">
						<input type="text" name="b_db37477a78610a7fd68c1eb65_39e8d41aaf" tabindex="-1" value="">
					</div>
				</div>
			</form>
		</div>
		<!--End mc_embed_signup-->

		<div class="v21_33">
			Made with <i class='bx bxs-heart'></i> by Do Labs
		</div>
	</div>
</template>

<script>

export default {
	name: 'Home',
	components: {}
}
</script>
